import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class MsgrService {
  loading = false;
  private cachedAlarms: any[];
  private subject = new BehaviorSubject<any[]>(null);

  constructor(private httpClient: HttpClient) {}

  listenForChange() {
    return this.subject.asObservable();
  }

  load() {
    if (this.cachedAlarms) {
      this.subject.next(this.cachedAlarms);
    } else {
      this.loading = true;
      this.httpClient.get('/api/msgr').subscribe((data: any[]) => {
        this.cachedAlarms = data;
        this.subject.next(this.cachedAlarms);
        this.loading = false;
      });
    }
  }
}
